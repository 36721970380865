import React, { useCallback, useContext } from 'react'
import { useState } from 'react';

import { Button, Layout, Menu, theme } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';

import FiltroComponent from '../components/FiltroComponent';
import TableComponent from '../components/Layout/Tablas/TableComponent';
import LogUserInformationContext from '../store/LogUser-context';

const { Header, Sider, Content } = Layout;

export const VisitsPage = () => {

  const [collapsed, setCollapsed] = useState(false);
  const [tableClientRows, setTableClientRows] = useState([]);

  const userLogCtx = useContext(LogUserInformationContext);


  const {
    token: { colorBgContainer },
  } = theme.useToken();


  const fetchClientVisitsRows = useCallback(async (users, airports, initialDate, endDate) => {
    try {
      const tokenKey = localStorage.getItem('LogTokenAccess') ? localStorage.getItem('LogTokenAccess') : '';
      const obtainedVisits = await fetch(`${userLogCtx.domainUrlAPI}/controllers/visits/getVisitsWebByBAAndDateFilters?baUsers=${users}&airports=${airports}&initialDate=${initialDate}&endDate=${endDate}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': tokenKey
        }

      });


      const visitsObtainedonverted = await obtainedVisits.json();
      const visitFormatedToTable = [];

      for (let visit of visitsObtainedonverted) {
        visitFormatedToTable.push({
          key: visit.tarea.idtarea,
          venue: visit.tarea.cliente.nombre,
          status: visit.tarea.cliente.idestatus === 1 ?
            <>Enabled <FontAwesomeIcon icon={(faCircle)} style={{ color: "#36c953", }} size="lg" />  </>
            :
            <>Disabled <FontAwesomeIcon icon={(faCircleMinus)} style={{ color: "#ce3b3b", float: 'right' }} size="lg" />  </>,
          // <>Disabled <FontAwesomeIcon icon={(faCircle)} style={{color: "red", backgroundColor: "red"}}  size="xl"/> </>,
          ba: `${visit.usuarioRegistro.nombre} ${visit.usuarioRegistro.apellidoPaterno}`,
          previous: visit.fechaPreviousVisitS,
          visit: visit.tarea.fechaProgramada
        })
      }

      setTableClientRows(visitFormatedToTable)




    }
    catch (err) {
      console.log('Error', err);
    }
  }, [userLogCtx.domainUrlAPI])


  return (
    <div>
      <FiltroComponent onFilter={fetchClientVisitsRows}></FiltroComponent>
      
      <div className='contenTable'>
        <TableComponent tableClientsRows={tableClientRows} ></TableComponent>
        {/* <TableMauricio></TableMauricio> */}
      </div>
      
    </div>
  )
}
