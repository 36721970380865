import React, { useState, } from 'react';
import { Link, Outlet } from 'react-router-dom'
import { Layout, Menu, Button, theme } from 'antd';
import { Navigate } from "react-router-dom";
import NavigationMain from "./../components/Layout/NavigationMain/NavigationMain";

//Imagenes
import logoGtrH from "../components/resources/img/logoHorizontal.png"
import menuBurger from "../components/resources/img/menuBurger.png"
import Cerrarmenu from "../components/resources/img/cerrarMenu.png"
import iconoDash from "../components/resources/img/iconoDashboard.png"
import iconoReporte from "../components/resources/img/iconoReporte.png"
import iconoVisits from "../components/resources/img/iconoVisits.png"


const { Header, Sider, Content } = Layout;




export const LayoutPage = (props) => {

  


  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  if( !localStorage.getItem('LogTokenAccess') ){
    return <Navigate to="/" replace />
  }

  return (
    <div>

      <NavigationMain onLogoutHandler={props.onLogoutHandler} />
      <Layout>

        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className='center'>
            <img className='iconoSideH' src={logoGtrH} alt="Logo GTR" />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            items={[
              {
                key: '1',
                icon: <img className='iconoSide2' src={iconoDash} alt="Icono GTR" />,
                label: <Link to="/dashboard">Dashboard</Link>,
              },
              {
                key: '2',
                icon: <img className='iconoSide2' src={iconoVisits} alt="Icono GTR" />,
                label: <Link to="/visitas">Visits</Link>,
              },
              // {
              //   key: '3',
              //   icon: <img className='iconoSide2' src={iconoVisits} alt="Icono GTR" />,
              //   label: <Link to="/visits">Visits</Link>,
              // },
            ]}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <img className='iconoCerrar' src={Cerrarmenu} alt="Menu GTR" /> : <img className='iconoSide' src={menuBurger} alt="Menu GTR" />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

