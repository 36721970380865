import React from 'react'
import { Tabs } from 'antd';
import './Tables.css';
import SalesTarget from '../Venue/SalesTarget';
import ShelfList from '../Venue/ShelfList';
import SecondaryList from '../Venue/SecondaryList';
import StockList from '../Venue/StockList';
import StoreList from '../Venue/StoreList';
import SuplementaryList from '../Venue/SuplementaryList';



const onChange = (key) => {
    // console.log(key);
};


const TabsComponent = ( props ) => {

    let content = <div> 
             <h1>Sorry, something went wrong</h1>
         </div>;

    if( props.visitDetailProp.tarea.idtarea !== 0){
        const sales = <SalesTarget detailVisit={props.visitDetailProp}></SalesTarget>;
        const shelf = <ShelfList detailVisit={props.visitDetailProp}></ShelfList>;
        const secondary = <SecondaryList detailVisit={props.visitDetailProp}></SecondaryList>;
        const stock = <StockList detailVisit={props.visitDetailProp}></StockList>;
        const store = <StoreList detailVisit={props.visitDetailProp}></StoreList>;
        const suplementary = <SuplementaryList detailVisit={props.visitDetailProp}></SuplementaryList>;

        const items = [
            {
                key: '1',
                label: `Sales Target`,
                children: <div>{sales}</div>,
            },
            {
                key: '2',
                label: `Shelf`,
                children: <div>{shelf}</div>,
            },
            {
                key: '3',
                label: `Secondary`,
                children: <div>{secondary}</div>,
            },
            {
                key: '4',
                label: `Stock`,
                children: <div>{stock}</div>,
            },
            {
                key: '5',
                label: `Store`,
                children: <div>{store}</div>,
            },
            {
                key: '6',
                label: `Supplementary`,
                children: <div>{suplementary}</div>,
            },
        ];

        content = <div className='p-20'>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>;

    }


    return (
        // <div className='p-20'>
        //     <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        // </div>
        content
    )
}

export default TabsComponent