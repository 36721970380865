import { Navigate } from "react-router-dom";
import Navigation from "../components/Layout/Navigation"


const Visits = () =>{

    if( !localStorage.getItem('LogTokenAccess') ){
        return <Navigate to="/" replace />
    }

    return (
        <Navigation />
    )
}


export default Visits;