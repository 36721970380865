import React from 'react'
import { Col, Row, List } from 'antd';
import botellaJagger from '../../resources/img/botellaJagger.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// const { Panel } = Collapse


const SecondaryList = ( props ) => {

    const elementsList = [
        {
            descripcion: 'Availability',
            dato: props.detailVisit.sec_aviability === 1 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}} size="xl"/>
            // dato: props.detailVisit.sec_aviability === 1 ? 'Yes':'No'
        },
        {
            descripcion: 'Visibility & VAPS:',
            dato: props.detailVisit.sec_idvisibilityvaps.idvisibilityVAPReport > 0 ?  props.detailVisit.sec_idvisibilityvaps.name : 'No Visibility & VAPS'
        },
        {
            descripcion: 'Facings',
            dato: props.detailVisit.sec_Facings < 0 ? 'No Facings' : props.detailVisit.sec_Facings
        },
        {
            descripcion: 'Depths:',
            dato: props.detailVisit.sec_Depths < 0 ? 'No Depths' : props.detailVisit.sec_Depths
        },
        {
            descripcion: 'Correct Share:',
            dato: props.detailVisit.sec_Share > 0 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}}  size="xl"/>
            // dato: props.detailVisit.sec_Share > 0 ? 'Yes': 'No'
        },
        {
            descripcion: 'Price Shown:',
            dato: props.detailVisit.sec_Priceshown > 0 ?`€ ${props.detailVisit.sec_Priceshownprice }` : 'No Price Shown'
        },
        {
            descripcion: 'Brand Communication:',
            dato: props.detailVisit.sec_Brandcomm > 0 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}} size="xl" />
            // dato: props.detailVisit.sec_Brandcomm > 0 ? 'Yes': 'No'
        }
        // ,
        // {
        //     descripcion: 'Minimum Price:',
        //     dato: ' € 2.35 '
        // },
        // {
        //     descripcion: 'Maximum Price:',
        //     dato: ' € 2.35 '
        // },
    
    ];
    

    return (
        <div>
            <Row>
                <Col span={14}>
                    <List
                        size="small"
                        bordered
                        dataSource={elementsList}
                        renderItem={(item) => <List.Item>
                            <p>
                                <strong>{item.title} </strong>
                            </p>
                            {item.descripcion} <span>{item.dato}</span>
                        </List.Item>}
                    />
                </Col>
                <Col span={10}>
                    <div className='imagenList' 
                    // style={{minHeight: '275px', maxHeight: '368px'}}
                    >
                        <img src={props.detailVisit.sec_Photos.length > 0 ? props.detailVisit.sec_Photos[0].base64 :botellaJagger} />
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default SecondaryList