import { useReducer } from "react";
import LogUserInformationContext from "./LogUser-context";

const defaultUserLog = {
    userLog: null,
}


const userLogReducer = ( state, action ) =>{

    if( action.type === 'LOGIN'){
        return {
            userLog: action.userLogInformation,
        }
    }


    if( action.type === 'CLEAR'){
        return defaultUserLog;
    }

    return defaultUserLog;

}


const LogUserProvider = ( props ) => {

    const [ userLogState, dispatchUserLogAction ] = useReducer(
        userLogReducer,
        defaultUserLog
    )

    //DEV
    // const domainUrlAPI = 'http://localhost:8000';
    //QA
    // const domainUrlAPI = 'http://44.214.235.79:8000';
    //PROD
    const domainUrlAPI = 'https://www.backend.gtr.wundertec.com';


    const addLogUserInformation = ( logUserInformation ) => {
        dispatchUserLogAction({ type: 'LOGIN', userLogInformation: logUserInformation})
    } 


    const clearUserInformation = () =>{
        dispatchUserLogAction({ type: 'CLEAR'})
    }


    const userLogContext = {
        userLogInformation: userLogState.userLog,
        domainUrlAPI: domainUrlAPI,
        addLogUserInformation: addLogUserInformation,
        clearUserInformation: clearUserInformation
    }

    return (
        <LogUserInformationContext.Provider value={ userLogContext } >
            {props.children}

        </LogUserInformationContext.Provider>
    )
}

export default LogUserProvider;