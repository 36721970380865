// import classes from './Navigation.module.css';
import './Navigation.css';
import React, { Component } from "react";
import Modal from "../UI/modal/Modal";
import NavigationMain from "./NavigationMain/NavigationMain";
import Sidebar from "../Sidebar";

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    showModalHandler = () => {
        this.setState({ showModal: true });
    }

    closeModalHandler = () => {
        this.setState({ showModal: false });
    }



    render() {
        return (
            <React.Fragment>
                <NavigationMain onLogoutHandler={this.props.onLogoutHandler} />
                <Sidebar></Sidebar>
                <div className="container-fluid py-4">
                    <div>
                        {this.state.showModal && <Modal onCloseModal={this.closeModalHandler} />}
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default Navigation;