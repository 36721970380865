import React, { useCallback, useContext } from 'react'
import { useState } from 'react';
// import { MenuFoldOutlined, MenuUnfoldOutlined, UploadOutlined, UserOutlined, VideoCameraOutlined, } from '@ant-design/icons';
import { Button, Layout, Menu, theme } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import logoGtrH from "./resources/img/logoHorizontal.png"

import menuBurger from "./resources/img/menuBurger.png"
import Cerrarmenu from "./resources/img/cerrarMenu.png"
import iconoReporte from "./resources/img/iconoReporte.png"
import iconoDash from "./resources/img/iconoDashboard.png"
import iconoUser from "./resources/img/iconoUser.png"
import FiltroComponent from './FiltroComponent';

import TableComponent from './Layout/Tablas/TableComponent';
import LogUserInformationContext from '../store/LogUser-context';
// import TableMauricio from './Layout/Tablas/TableMauricio';



const { Header, Sider, Content } = Layout;
const Sidebar = () => {


    const [collapsed, setCollapsed] = useState(false);
    const [ tableClientRows, setTableClientRows ] = useState( [] );
    
    const userLogCtx  = useContext( LogUserInformationContext );


    const {
        token: { colorBgContainer },
    } = theme.useToken();


    const fetchClientVisitsRows = useCallback( async ( users, airports, initialDate, endDate ) =>{
        try{
            const tokenKey =  localStorage.getItem('LogTokenAccess') ? localStorage.getItem('LogTokenAccess') : '';
            const obtainedVisits = await fetch(`${userLogCtx.domainUrlAPI}/controllers/visits/getVisitsWebByBAAndDateFilters?baUsers=${ users }&airports=${ airports }&initialDate=${ initialDate }&endDate=${ endDate }`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': tokenKey
                }

            });


            const visitsObtainedonverted = await obtainedVisits.json();
            const visitFormatedToTable = []; 

            for ( let visit of visitsObtainedonverted ){
                visitFormatedToTable.push({
                    key: visit.tarea.idtarea,
                    venue: visit.tarea.cliente.nombre,
                    status: visit.tarea.cliente.idestatus === 1 ? 
                    <>Enabled <FontAwesomeIcon icon={(faCircle)} style={{color: "#36c953",}} size="lg" />  </>
                    : 
                    <>Disabled <FontAwesomeIcon icon={(faCircleMinus)} style={{color: "#ce3b3b", float: 'right'}} size="lg" />  </>,
                    // <>Disabled <FontAwesomeIcon icon={(faCircle)} style={{color: "red", backgroundColor: "red"}}  size="xl"/> </>,
                    ba: `${visit.usuarioRegistro.nombre} ${visit.usuarioRegistro.apellidoPaterno}`,
                    previous: visit.fechaPreviousVisitS,
                    visit: visit.tarea.fechaProgramada
                })
            }

            setTableClientRows( visitFormatedToTable )




        }
        catch( err ){
            console.log( 'Error', err );
        }
    }, [userLogCtx.domainUrlAPI])


    return (
        <div>
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <div className="demo-logo-vertical" />
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        items={[
                            {
                                key: '0',
                                icon: <img className='iconoSideH' src={logoGtrH} alt="Logo GTR" />,
                            },
                            {
                                key: '1',
                                icon: <img className='iconoSide' src={iconoReporte} alt="Icono GTR" />,
                                label: 'Report',
                            },
                            {
                                key: '2',
                                icon: <img className='iconoSide' src={iconoDash} alt="Icono GTR" />,
                                label: 'Dashboard',
                            },
                            // {
                            //     key: '3',
                            //     icon: <img className='iconoSide' src={iconoUser} alt="Icono GTR" />,
                            //     label: 'User',
                            // },
                        ]}
                    />
                </Sider>
                <Layout>
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    >
                        <Button
                            type="text"
                            icon={collapsed ? <img className='iconoCerrar' src={Cerrarmenu} alt="Menu GTR" /> : <img className='iconoSide' src={menuBurger} alt="Menu GTR" />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />


                    </Header>
                    
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 280,
                            background: colorBgContainer,
                        }}
                    >
                        <FiltroComponent onFilter= {fetchClientVisitsRows}></FiltroComponent>
                        <div className='contenTable'>
                            <TableComponent tableClientsRows= { tableClientRows} ></TableComponent>
                            {/* <TableMauricio></TableMauricio> */}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default Sidebar