import React from 'react'
import { Col, Row, List } from 'antd';
import botellaJagger from '../../resources/img/botellaJagger.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// const { Panel } = Collapse


const ShelfList = (props) => {

    console.log("Tarea", props.detailVisit.tarea.idtarea)
    const elementsList = [
        {
            descripcion: 'Availability',
            // dato: props.detailVisit.shelf_aviability === 1 ? 'Yes':'No'
            dato: props.detailVisit.shelf_aviability === 1 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}} size="xl"/>
        },
        {
            descripcion: 'Visibility & VAPS:',
            dato: props.detailVisit.shelf_idvisibilityvaps.idvisibilityVAPReport > 0 ?  props.detailVisit.shelf_idvisibilityvaps.name : 'No Visibility & VAPS'
        },
        {
            descripcion: 'Facings',
            dato: props.detailVisit.shelf_Facings < 0 ? 'No Facings' : props.detailVisit.shelf_Facings
        },
        {
            descripcion: 'Depths:',
            dato: props.detailVisit.shelf_Depths < 0 ? 'No Depths' : props.detailVisit.shelf_Depths
        },
        {
            descripcion: 'Correct Share:',
            dato: props.detailVisit.shelf_Share > 0 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}} size="xl" />

            // dato: props.detailVisit.shelf_Share > 0 ? 'Yes': 'No'
        },
        {
            descripcion: 'Price Shown:',
            dato: props.detailVisit.shelf_PriceShown > 0 ?`€ ${props.detailVisit.shelf_PriceShownPrice}` : 'No'
        },
        {
            descripcion: 'Brand Communication:',
            dato: props.detailVisit.shelf_BrandComm > 0 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}} size="xl" />
            // dato: props.detailVisit.shelf_BrandComm > 0 ? 'Yes': 'No'
        },
        {
            descripcion: 'Minimum Price:',
            dato: props.detailVisit.minimum_price === "-1" ? 'No Minimum Price': `€ ${props.detailVisit.minimum_price}`
        },
        {
            descripcion: 'Maximum Price:',
            dato: props.detailVisit.maximum_price === "-1" ? 'No Maximum Price': `€ ${props.detailVisit.maximum_price}`
        }
    ]

    return (
        <div>
            <Row>
                <Col span={14}>
                    <List
                        size="small"
                        bordered
                        dataSource={elementsList}
                        renderItem={(item) => <List.Item>
                            <p>
                                <strong>{item.title} </strong>
                            </p>
                            {item.descripcion} <span>{item.dato}</span>
                        </List.Item>}
                    />
                </Col>
                <Col span={10}>
                    <div className='imagenList' 
                    // style={{minHeight: '275px', maxHeight: '368px'}}
                    >
                        <img src={ props.detailVisit.shelf_Photos.length > 0 ? props.detailVisit.shelf_Photos[0].base64 :botellaJagger } />
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default ShelfList