import React from 'react'
import { Col, Row, List } from 'antd';
import botellaJagger from '../../resources/img/botellaJagger.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// const { Panel } = Collapse


const SuplementaryList = ( props ) => {

    const elementsList = [
        {
            title: 'Customer Feedback',
            dato: props.detailVisit.suplinfo_customerfeed === 1 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}} size="xl"/>
            // dato: props.detailVisit.suplinfo_customerfeed === 1 ? 'Yes':'No'
        }
    
    ];



    for( let keyPoint of props.detailVisit.suplinfo_key_point ) {

        elementsList.push(
            {
                title: '',
                descripcion: keyPoint.keyPoint,
            },
        )

    }


    elementsList.push(
        {
            title: 'To do:',
            descripcion: props.detailVisit.suplinfo_to_do === "" ? 'No To Do Description': props.detailVisit.suplinfo_to_do,
        }
    )

    elementsList.push(
        {
            title: 'Category Performance:',
            descripcion: props.detailVisit.suplinfo_catperform === "" ? 'No Category Performance': props.detailVisit.suplinfo_catperform,
        }

    )

    elementsList.push(
        {
            title: 'Competitor Activity:',
            descripcion: props.detailVisit.suplinfo_compactivity === "" ? 'No Competitor Activity': props.detailVisit.suplinfo_compactivity,
        }
    )
    
    return (
        <div>
            <Row>
                <Col span={14}>
                    <List
                        size="small"
                        bordered
                        dataSource={elementsList}
                        renderItem={(item) => <List.Item>
                            <p>
                                <strong>{item.title} </strong>
                            </p>
                            {item.descripcion} <span style={ { marginTop: '-25px' }}>{item.dato}</span>
                        </List.Item>}
                    />
                </Col>
                <Col span={10}>
                    <div className='imagenList'>
                        <img src={botellaJagger} />
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default SuplementaryList