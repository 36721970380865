import classes from './NavigationMain.module.css';
import botonSalir from '../../resources/img/botonSalir.png'
import { useNavigate } from 'react-router-dom';

const NavigationMain = props => {
    // console.log("Props ",props.onLogoutHandler)

    const navigate = useNavigate();

    const onLogoutHandler = ()=>{
        localStorage.removeItem('LogTokenAccess');
        navigate('/')
        // return redirect('/');
    }

    return (
        <div className={classes['main-bar']}>
            <div className='botonSalir' href="#" onClick={onLogoutHandler}>
                <img src={botonSalir} alt="Icono GTR" />
            </div>
        </div>
    )

}
export default NavigationMain;
