import { Col, Button, Row, Select, DatePicker, Space } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import iconoFiltro from './resources/img/iconoFiltrar.png';
import './FilterComponent.css';
import LogUserInformationContext from '../store/LogUser-context';

const { RangePicker } = DatePicker;

  

const FiltroComponent = ( props) => {

    const userLogCtx  = useContext( LogUserInformationContext );
    const [ regionFilter, setRegionFilter ] = useState([]); 
    
    const [ filtersValue, setFiltersValue ] = useState( {
        regions: [],
        countries: [],
        airports: [],
        bas: []
    });

    const [ countryFilter, setCountryFilter ] = useState([]); 
    const [ airportFilter, setAirportFilter ] = useState([]);
    const [ ambassadorFilter, setAmbassadorFilter ] = useState([]); 

    const [ countrySelected, setCountrySelected] = useState([]);
    const [ baSelected, setBaSelected ] = useState([]);
    const [ airportsSelected, setAirportsSelected] = useState([]);
    const [ dateRangeSelected, setDateRangeSelected] = useState(['',''])

    const [ baFilterValid, setBaFilterValid] = useState( true );
    const [ rangeDateFilterValid, setRangeDateFilterValid ] = useState( true );
    
    const basRef = useRef();

    const fetchCatalogue = useCallback( 
        async () =>{
        try{
            // const userObject = await JSON.parse(localStorage.getItem('UserLogInf'));
            const tokenKey =  localStorage.getItem('LogTokenAccess') ? localStorage.getItem('LogTokenAccess') : '';
            const catalogueFilters = await fetch(`${userLogCtx.domainUrlAPI}/controllers/visits/getCataloguesWeb`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': tokenKey
                },

            });

            const regionFilterValue = [];


            const catalogueFiltersConverted = await catalogueFilters.json();
            // console.log("Catalogue filters" , catalogueFiltersConverted );
            for (let region of catalogueFiltersConverted.regions){
                regionFilterValue.push({
                    value: region.idregion,
                    label: region.region

                })
            }



            setRegionFilter(regionFilterValue);
            setFiltersValue(
                catalogueFiltersConverted
            );

        }
        catch( err ){
            console.log( 'Error', err );
        }
    }
    , [userLogCtx.domainUrlAPI])

    const onChangeRegion = ( currentRegionsSelected ) => {

        const countriesList = [];

        if( currentRegionsSelected.length > 0 ){

            for( let country of filtersValue.countries ){
                if( currentRegionsSelected.includes( country.region.idregion ) ){
                    countriesList.push({ 
                        value: country.idpais,
                        label: country.pais,
                    })
                }
            }

        }
        
        setCountryFilter( countriesList );
        setAirportsSelected([]);
        setCountrySelected( [] );
        setBaSelected( [] );
        setAirportFilter( [] );
        setAmbassadorFilter( [] );
        

    }

    const onChangeCountry = ( currentCountriesSelected ) => {
 
        const airportList = [];
        for( let airport of filtersValue.airports ){
            if( currentCountriesSelected.includes( airport.pais.idpais ) ){
                airportList.push({ 
                    value: airport.idSucursal,
                    label: airport.direccion,
                })
            }
        }

        const basList = [];
        for( let ba of filtersValue.bas ){
            if( currentCountriesSelected.includes( ba.pais.idpais ) ){
                basList.push({ 
                    value: ba.idUsuario,
                    label: ba.nombre,
                })
            }
        }


        setCountrySelected( currentCountriesSelected );
        setAirportFilter( airportList );
        setAmbassadorFilter( basList );
        setBaSelected( [] );
        setAirportsSelected([]);
    }


    const onChangeBA = ( currentBasSelected ) => {
        setBaSelected(currentBasSelected);
    }

    const onChangeAirtports = ( currentAirportsSelected ) => {
        setAirportsSelected(currentAirportsSelected)
    }

    const onChangeDate = ( dayjs, dateString ) =>{
        setDateRangeSelected( dateString );


    }

    const onClickFilter = () =>{

        if( baSelected.length === 0 
            // || airportsSelected.length === 0
            ){
            setBaFilterValid(false);
            return;
        }

        setBaFilterValid(true);

        if (dateRangeSelected[0] === '' || dateRangeSelected[1] === ''){
            setRangeDateFilterValid(false);
            return;
        }

        setRangeDateFilterValid(true);
        props.onFilter( baSelected.toString(),
        "1" ,
        // airportsSelected.toString(),
         dateRangeSelected[0], dateRangeSelected[1] )

    }

    useEffect( () => {
        fetchCatalogue()

    }, [ fetchCatalogue ]);



    return (
        <div className='contentFiltro'>
            <Row>
                <Col span={4}>
              
                    <p>
                        Regions:
                    </p>
                    <Select
                        showSearch
                        className='select-filter'
                        onChange={onChangeRegion}
                        dropdownStyle={{overflowX: 'scroll'}}
                        style={{
                            width: '90%',
                        }}
                        mode="multiple"
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={
                            regionFilter
                    }
                    />
                </Col>
                <Col span={4}>
            
                    <p>
                        Countries:
                    </p>
                    <Select
                        showSearch
                        className='select-filter'
                        value={countrySelected}
                        onChange={onChangeCountry}
                        style={{
                            width: '90%',
                        }}
                        mode="multiple"
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={
                            countryFilter

                    }
                    />
                </Col>
                <Col span={4}>
                    <p>
                        Airport:
                    </p>
                    <Select
                        onChange={onChangeAirtports}
                        value={airportsSelected}
                        className='select-filter'
                        popupMatchSelectWidth={false}
                        // dropdownStyle={{width: '1000px', overflowX:'auto', background: '#0f1d09', color: 'white'}}
                        showSearch
                        status={ baFilterValid ? '' : "error"}
                        style={{
                            width: '90%',
                        }}
                        mode="multiple"
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={
                            airportFilter

                    }
                    />
                </Col>
                <Col span={4}>
                    <p>
                        B.A:
                    </p>
                    <Select
                        showSearch
                        value={baSelected}
                        className='select-filter'
                        onChange={onChangeBA}
                        popupMatchSelectWidth={false}
                        ref={basRef}
                        status={ baFilterValid ? '' : 'error'}
                        style={{
                            width: '90%',
                        }}
                        allowClear
                        mode="multiple"
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={
                            ambassadorFilter

                    }
                    />
                </Col>
                <Col span={4}>
                    <p>
                        Date:
                    </p>
                    <Space className='full' direction="vertical" size={12} >
                        <RangePicker  
                        status={ rangeDateFilterValid ? '' : "error"}
                        onCalendarChange={onChangeDate}
                            
                        />
                    </Space>
                </Col>
                <Col className='center' span={3}>
                    <p>
                        Filter:
                    </p>
                    <Button className='botonFiltro' type="" onClick={onClickFilter}>
                    Filter Data <img src={iconoFiltro} alt="Cars Selected" />
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default FiltroComponent