import { useState } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import { LayoutPage } from './pages/LayoutPage';
import LogUserProvider from './store/LogUserProvider';
import { VisitsPage } from './pages/VisitsPage';
import { Default } from './pages/Default';
import  Visits  from './pages/Visits';


function App() {
  const [count, setCount] = useState(0)

  return (
    <div>
      <LogUserProvider>
        <Routes>
          <Route path="/" element={<LoginPage />}></Route>

          <Route path='/' element={<LayoutPage />} >
            <Route path="visitas" element={<VisitsPage />}></Route>
            <Route path="dashboard" element={<Dashboard />}></Route>
            <Route path="visits" element={<Visits/>}></Route>
            <Route path="*" element={<Default />}></Route>
          </Route>
        </Routes>
      </LogUserProvider>
    </div>
  )


}

export default App;
