import { Alert, Button, Checkbox, Form, Input } from 'antd';
import classes from './Login.module.css';
import logoGtr from "./../resources/img/gtrLogo.png"
import { useState } from 'react';
import { Navigate } from 'react-router-dom';


const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const Login = props => {



    const [ errorAuthetication, setErrorAuthentication ] = useState( false );
    const onFinish = async (values) => {

        const responseAuthentication = await props.onLoginHandler( values );

        if( responseAuthentication.authenticationResponse === 0){   
            setErrorAuthentication( true );
            // console.log("Authentication Failed");
        }
        // console.log('Success:', values);
    };

    if( localStorage.getItem('LogTokenAccess') ){
        return <Navigate to="/dashboard" replace />
    }

    return (
        <div className={classes['bgLogin']}>
            <div className={classes['contentForm']}>
                <div className='center'>
                    <img src={logoGtr} alt="icono GTR" />
                </div>
                <Form
                    name="basic"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    { errorAuthetication &&  <Alert message="Username or password incorrect" type="error"/>   }


                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{
                            offset: 0,
                            span: 12,
                        }}
                    >
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>



                    <Form.Item
                        wrapperCol={{
                            offset: 6,
                            span: 12,
                        }}
                    >
                        <Button type="primary" className='botonLogin' htmlType="submit">
                            LOGIN
                        </Button>
                    </Form.Item>
                </Form>
                {/* <form  className='center' onSubmit={props.onLoginHandler} >
                    <input className='botonLogin' style={{color:'white'}} type="submit" value="Entrar" />
                </form> */}
            </div>


        </div>
    )
}

export default Login;